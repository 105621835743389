import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Gebaudeversicherung = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Was deckt eine Gebäudeversicherung?" showCalc={false} />
            <Article>
                <p>
                    Der Kreditvertrag ist unterschrieben, das Grundstück erworben, der{" "}
                    <Link to="/artikel/bauen/" target="_blank" rel="noreferrer noopener">
                        Hausbau
                    </Link>{" "}
                    geplant - jetzt kann doch nichts mehr schiefgehen, oder? Nicht so voreilig: Sobald der{" "}
                    <Link to="/artikel/baufinanzierung/" target="_blank" rel="noreferrer noopener">
                        Bau
                    </Link>{" "}
                    eines Gebäudes beginnt, kann es zu Schäden kommen; und das kann teuer werden. Damit du auf den
                    Kosten nicht sitzen bleibst, ist es ratsam, eine Gebäudeversicherung abzuschließen. Was das ist und
                    was sie abdeckt, verraten wir dir im folgenden Artikel.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was ist eine Gebäudeversicherung?</h2>
                <p>
                    In Paketform abgeschlossen, schützt eine Gebäudeversicherung gegen potenzielle Schäden durch Feuer,
                    Leitungswasser, Sturm sowie gegen eigene Vergehen, die in den Bereich der Haftpflicht fallen. Da
                    Schadensquellen, die unwahrscheinlich oder schlicht vermeidbar sind, von einer Gebäudeversicherung
                    nicht gedeckt sind, solltest du darüber hinaus allerdings eine Haushaltsversicherung abschließen.
                </p>
                <hr />

                <h2>Worauf bezieht sich die Gebäudeversicherung?</h2>
                <p>Eine Gebäudeversicherung besteht aus mehreren Komponenten. Dazu gehören etwa:</p>
                <ul>
                    <li>
                        Feuerversicherung: Brandschäden, Schänden durch den direkten Einschlag von blitzen, Explosionen
                        Flug. Schäden infolge von Löscharbeiten, Niederreißen oder Ausräumen und Abhandenkommen
                    </li>
                    <li>
                        Sturmschadenversicherung: Schäden durch Sturm mit Höchstgeschwindigkeiten von mindestens 60 km/h
                        sowie durch infolgedessen umgestürzte Gegenstände (Masten, Bäume etc.), Schäden durch Schnee,
                        Hagel, Felsen, Steinschlag und Erdrutsch
                    </li>
                    <li>
                        Leitungswasserschadenversicherung: Austreten von Wasser aus wasserführenden und an diese
                        angeschlossenen Maschinen, Bruchschäden sowie Schäden durch Frost an Leitungen, an
                        angeschlossenen Maschinen sowie Kosten für das Entfrosten
                    </li>
                </ul>
                <p>
                    Darüber hinaus kannst du Leistungen, die in dieser Grunddeckung nicht inkludiert sind, durch
                    Zusatzversicherungen erwerben. Achte hier allerdings darauf, nicht ein und denselben Schadensfall
                    durch mehrere Versicherungen abzudecken bzw. generell zu viele Versicherungsleistungen in Anspruch
                    zu nehmen. Eine solche Übersicherung kann teuer werden. Die meisten Anbieter von
                    Gebäudeversicherungen bieten auf ihren websites Vergleichsmöglichkeiten an, die ähnlich
                    funktionieren wie unser{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>
                    . So kannst du dich rasch, unkompliziert und selbstständig über die voraussichtlichen Kosten
                    informieren.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"gebaudeversicherung"}></BreadcrumbList>
            <ArticleStructuredData page={"gebaudeversicherung"} heading={"Was deckt eine Gebäudeversicherung?"} />
        </Layout>
    );
};

export default Gebaudeversicherung;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.gebaudeversicherung", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
